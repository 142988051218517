@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

html,
body,
#root {
  margin: 0px;
}

input[type="file"]::file-selector-button {
  border: 2px solid #33748b;
  padding: 0.6em 0.6em;
  border-radius: 0.2em;
  background-color: #33748b;
  transition: 1s;
  color: white;
}

input[type="file"]::file-selector-button:hover {
  background-color: #33748b;
  border: 2px solid #33748b;
}