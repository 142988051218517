.current-year {
  background: #ffffff;
  border: 1px solid #dee2e6;
  font: normal normal bold 18px/25px Roboto;
}

.title {
  padding-left: 8px;
  font: normal normal bold 16px/21px Roboto !important;
}

.subtitle {
  padding-left: 8px;
  font: normal normal normal 16px/24px Roboto !important;
}

.th {
  width: 77px;
  height: 60px;
  border: 1px solid #dee2e6;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: none;
  text-align: center;
}

.product {
  line-height: 22px !important;
  padding-left: 8px;
  text-align: start;
  width: 266px;
  font: normal normal normal 14px Roboto;
  background-color: #ffffff;
  border: 1px solid #146600;
}

.accept-refuse {
  border-left: none !important;
  text-transform: uppercase;
  font: normal normal bold 16px/16px Roboto;
  width: 92px;
  border: 1px solid #dee2e6;
  background: #ffffff;
}

.buyer {
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-right: none;
  padding-left: 8px;
  text-align: start;
}

.th-period {
  display: grid;
  width: 300px;
}

.table-view-demand {
  margin: 15px;
}

.td-view-demand {
  width: 77px;
  height: 45px;
  text-align: center;
  background: #85d46c 0% 0% no-repeat padding-box;
  border: 1px solid #146600;
}

.td-view-demand {
  width: 77px;
  height: 45px;
  text-align: center;
  background: #85d46c 0% 0% no-repeat padding-box;
  border: 1px solid #146600;
}

.btn-back {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #146600;
  color: #146600;
  font: normal normal bold 15px/20px Nunito Sans;
  height: 38px;
  margin-bottom: 200px;
  text-align: center;
  width: 92px;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
}

.btn-back:hover {
  background-color: #479632;
  color: #ffffff;
  border: 1px solid #479632;
}

.btn-conclude {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  text-transform: uppercase;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 5px;
  background: #cccccc;
  box-shadow: 0px 3px 6px #00000029;
  font: normal normal bold 15px/20px Nunito Sans;
}
